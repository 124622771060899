<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')" class="cur">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="vr3dClassWrap">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../../public/imgs/index/banner1.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../public/imgs/index/banner2.png" alt="" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="why">
      <p><img src="../../public/imgs/index/whyTest.png" alt="" /></p>
      <p>关于我们</p>
      <p>10年专业商业办公综合体建筑设计、施工、系统服务和设施管理服务经验</p>
      <p>中国建筑装饰协会评为AAA级信用企业，ISO9001质量管理体系认证</p>
      <p>建筑装修装饰工程专业承包一级</p>
      <div class="whyMain">
        <div>
          <img src="../../public/imgs/index/why1.png" alt="" />
          <p>成本优势</p>
          <p>
            公司有专用的供应商以及供应链管理，更好的降低工程材料采购成本、库存成本。
          </p>
        </div>
        <div>
          <img src="../../public/imgs/index/why2.png" alt="" />
          <p>速度优势</p>
          <p>再“拉动”方面，提高快速响应能力，进行及时快速的工程建设。</p>
        </div>
        <div>
          <img src="../../public/imgs/index/why3.png" alt="" />
          <p>聚焦优势</p>
          <p>更好地满足特定顾客群体的需求。</p>
        </div>
        <div>
          <img src="../../public/imgs/index/why4.png" alt="" />
          <p>机动优势</p>
          <p>
            在“推动”方面提高企业对市场的预测和反应能力，加强客户关系，及时满足市场需求。
          </p>
        </div>
      </div>
    </div>
    <div class="seeTitle"></div>
    <p class="anli">案例</p>
    <div class="workItem">
      <div @click="item('5')">
        <img src="../../public/imgs/indexNew/home1.png" alt="" />
        <div class="advantageRightText">
          <p>兴业银行西安分行</p>
          <p>地址：西安市雁塔区唐延路1号 面积：2000㎡</p>
        </div>
      </div>
      <div @click="item('4')">
        <img src="../../public/imgs/indexNew/home2.png" alt="" />
        <div class="advantageRightText">
          <p>广发银行榆林分行</p>
          <p>地址：榆林市高新技术产业园区沙河路152号 面积：1805㎡</p>
        </div>
      </div>
      <div @click="item('14')">
        <img src="../../public/imgs/indexNew/home3.png" alt="" />
        <div class="advantageRightText">
          <p>建设银行西影路支行</p>
          <p>地址：西安市西影路105号水木兰亭小区1幢1单元10102室 面积：255㎡</p>
        </div>
      </div>
      <div @click="item('13')" style="margin-left: 50px">
        <img src="../../public/imgs/indexNew/home4.png" alt="" />
        <div class="advantageRightText">
          <p>招商银行锦业二路自助银行</p>
          <p>地址：西安市长安区锦业二路法士特赛斐广场</p>
        </div>
      </div>
    </div>
    <div class="moreWrap" @click="itemJump"></div>
    <div class="newsTitle"></div>
    <p class="anli">新闻资讯</p>
    <div class="newsBg" @click="jumpNew('4')">
      <div class="newsTl">
        <span>同心抗疫 共克时艰 我们在行动</span>
        <img src="../../public/imgs/indexNew/detail.png" alt="" />
      </div>
      <div class="newsCt">
        <span>
          时至年末，疫情突发，防控措施不断升级，疫情就是命令，防控就是责任。重新席卷而来的新冠病毒肺炎疫情让西安又按下了“暂停键”，无数医务人员、警察义无反顾奔赴一线。
          为进一步加强疫情防控力度，各小区开始实施封闭式管理，无形中加大了社区的工作量。西安创星装饰工程有限公司：杨帆 骆训文两位员工在获得家人和公司领导的理解和支持
          后第一时间站了出来，下沉一线，主动加入到了社区防疫志愿者队伍中。在志愿服务期间，他们积极承担核酸检测、秩序维护、入户检查、“喊楼”通知等工作。得知封控社区部分
          群众短缺急用生活物资时...
        </span>
      </div>
    </div>
    <div class="lcBg">
      <div class="qylc">
        <span>企业历程</span>
        <img src="../../public/imgs/indexNew/detail.png" alt="" style="vertical-align:middle"/>
      </div>
    </div>
    <!-- <div class="newsMain">
      <div :class="{ newsMain1: newsMainShow }" @click="jumpNew('1')">
        <div>2021-02-22</div>
        <div></div>
        <div class="title">办公室装饰的特点和技巧</div>
        <div>
          办公室装饰是一个比较烦琐的工程，尤其要注意细节。在设计上，可以充分利用办公室的所有空间进行办公。使得办公设计更具特色，从而使员工在工作中感受到温馨，让工作效率更大程度的提高，提升公司的形象。
        </div>
        <div class="newsGo"></div>
      </div>
    </div> -->
    <div class="tenetTitle"></div>

    <div class="tenetWrap">
      <div class="tenetText">
        <p>服务宗旨</p>
        <p></p>
        <div>
          创星秉承着“创空间
          新境界”的品牌理念，遵循“设计是灵魂，质量是命脉”的服务宗旨，一直延续着“客户利益为先，责任人至上”的工作作风，通过服务升级、技术创新、工艺规范、严格自检等工作管理方式，力求为客户提供和交付优质工程，做到让客户真正放心、省心。
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {
      workList: [
        "金融办公",
        "别墅住宅",
        "商业综合体",
        "医疗教育",
        "休闲运动",
        "展览展会",
      ],
      n: 0,
      newsMainShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
    new Swiper(".swiper-container", {
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
  methods: {
    itemJump() {
      this.$router.push({
        path: "/case",
      });
    },
    newJump() {
      this.$router.push({
        path: "/newsInfo",
      });
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 1700) {
        this.newsMainShow = true;
      }
    },
    changeList(index) {
      this.n = index;
    },
    caseJump() {
      this.$router.push({
        path: "/case",
      });
    },
    meJump() {
      this.$router.push({
        path: "/me",
      });
    },
    item(index) {
      this.$router.push({
        path: "/caseItem",
        query: {
          id: index,
        },
      });
    },
    jumpNew(index) {
      if (index == 1) {
        this.$router.push({
          path: "/newsItem1",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/newsItem2",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsItem3",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/newsInfo",
        })
      }
    },
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style scoped>
.newsBg{
  width: 1500px;
  height: 180px;
  background: url("../../public/imgs/indexNew/newsBg.png") no-repeat;
  margin-left: 250px;
  padding: 20px;
  cursor: pointer;
}
.newsTl {
  margin-top:20px;
}
.newsTl span {
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  margin-left: 60px;
  vertical-align: middle
}
.newsTl img {
  margin-left: 10px;
  vertical-align:middle
}
.newsCt {
  width:1300px;
  margin-top:20px;
  margin-left: 60px;
}
.newsCt span {
  font-weight: 560;
  font-size: 15px;
  line-height:30px
}

.lcBg {
  width: 1500px;
  height: 50px;
  background: url("../../public/imgs/indexNew/lcBg.png") no-repeat;
  margin-left: 250px;
  padding: 20px;
}
.qylc {
  margin-top:10px;
  cursor: pointer;
}
.qylc span {
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  margin-left: 60px;
  vertical-align: middle
}

.qylc img {
  margin-left: 10px;
  vertical-align:middle
}

p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  z-index: 8888;
  top: 0;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.vr3dClassWrap {
  width: 1920px;
  height: 845px;
  position: relative;
  margin-top: 100px;
}

.vr3dClassWrap img {
  height: 845px;
  width: 1920px;
}
/* why */
.why {
  width: 100%;
  height: 720px;
  background: url("../../public/imgs/index/whyBg.png") no-repeat center center;
  background-size: 100% 100%;
  overflow: hidden;
}
.why > p {
  text-align: center;
}
.why > p:nth-of-type(1) {
  margin-top: 100px;
}
.why > p:nth-of-type(2) {
  color: #0087ef;
  font-size: 30px;
  font-weight: 900;
  margin-top: 20px;
  letter-spacing: 4px;
}
.why > p:nth-of-type(3) {
  font-size: 22px;
  margin-top: 20px;
  letter-spacing: 2px;
}
.why > p:nth-of-type(4) {
  font-size: 22px;
  margin-top: 10px;
  letter-spacing: 2px;
}
.why > p:nth-of-type(5) {
  font-size: 22px;
  margin-top: 10px;
  letter-spacing: 2px;
}

.whyMain {
  display: flex;
  width: 1000px;
  margin: 90px auto;
}
.whyMain > div {
  text-align: center;
  flex: 1;
  padding: 0 30px;
}
.whyMain p:nth-of-type(1) {
  font-size: 26px;
  font-weight: 900;
  margin: 10px 0;
}
.whyMain img {
  vertical-align: middle;
}
.line {
  width: 1600px;
  margin: 0 auto;
  height: 1px;
  background: #dddddd;
}

.seeTitle {
  width: 1700px;
  height: 55px;
  background: url("../../public/imgs/index/see.png") no-repeat left center;
  margin: 50px auto 0;
  border-bottom: 1px solid #ddd;
}
.anli {
  width: 1700px;
  height: 70px;
  font-weight: 900;
  font-size: 30px;
  line-height: 70px;
  margin: 0 auto;
}

.workItem {
  width: 1700px;
  margin: 0 auto;
  height: 1040px;
}
.workItem > div {
  margin-bottom: 40px;
  width: 825px;
  height: 520px;
  float: left;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.workItem img {
  width: 100%;
  height: 100%;
}
.workItem img:hover {
  cursor: pointer;
}
.workItem > div:hover .advantageRightText > p:nth-of-type(1) {
  animation: twisterP1 0.4s forwards;
}
.workItem > div:hover .advantageRightText > p:nth-of-type(2) {
  animation: twisterP2 0.4s forwards;
}
.workItem > div:nth-of-type(1) {
  margin-right: 50px;
}

.moreWrap {
  width: 150px;
  height: 36px;
  margin: 100px auto;
  cursor: pointer;
  background: url("../../public/imgs/index/more.png");
}
.moreWrap:hover {
  background: url("../../public/imgs/index/moreHover.png");
}
.advantageRightText {
  width: 100%;
  height: 100px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0px;
  font-size: 14px;
  color: #000;
  transition: all 0.4s;
}
.workItem > div:hover .advantageRightText {
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
.advantageRightText p:nth-of-type(1) {
  font-weight: 600;
  font-size: 20px;
}
.advantageRightText p {
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
@keyframes twisterP1 {
  0% {
    font-size: 20px;
    margin: 0;
  }
  100% {
    font-size: 30px;
    margin: 30px;
  }
}
@keyframes twisterP2 {
  0% {
    margin-top: 10px;
    padding: 0 20px;
  }
  100% {
    margin-top: 400px;
    padding: 0 30px;
    font-size: 22px;
  }
}

.newsGo {
  width: 70px;
  height: 24px;
  margin-top: 40px;
  cursor: pointer;
  background: url("../../public/imgs/index/go.png");
}
.newsGo:hover {
  background: url("../../public/imgs/index/goHover.png");
}
.newsTitle {
  width: 1700px;
  height: 55px;
  background: url("../../public/imgs/index/news.png") no-repeat left center;
  margin: 50px auto 0;
  border-bottom: 1px solid #ddd;
}
.newsMain {
  width: 1500px;
  margin: 0 auto;
  height: 260px;
  margin-top: 40px;
  position: relative;
}
.newsMain .title {
  color: #000;
  transition: all 0.5s;
}
.newsMain > div:hover .title {
  color: #0087ef;
}
.newsMain > div {
  width: 100%;
  position: absolute;
  cursor: pointer;
}
.newsMain > div:nth-of-type(1) {
  top: 0;
  left: 0;
  opacity: 1;
}
.newsMain1 {
  animation: newMain1 1s;
}
.newsMain2 {
  animation: newMain2 1s;
}
.newsMain3 {
  animation: newMain3 1s;
}
.newsMain > div:nth-of-type(2) {
  top: 0;
  left: 600px;
  opacity: 1;
}
.newsMain > div:nth-of-type(3) {
  top: 0;
  left: 960px;
  opacity: 1;
}
@keyframes newMain1 {
  0% {
    opacity: 0;
    left: 3000px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
@keyframes newMain2 {
  0% {
    opacity: 0;
    left: 3000px;
  }
  100% {
    opacity: 1;
    left: 600px;
  }
}
@keyframes newMain3 {
  0% {
    opacity: 0;
    left: 3000px;
  }
  100% {
    opacity: 1;
    left: 960px;
  }
}
.newsMain > div > div:nth-of-type(1) {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 6px;
}
.newsMain > div > div:nth-of-type(2) {
  width: 70px;
  height: 1px;
  background: #000000;
  margin-bottom: 16px;
}
.newsMain > div > div:nth-of-type(3) {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 27px;
}
.newsMain > div > div:nth-of-type(4) {
  font-size: 14px;
  color: #666666;
  line-height: 24px;
}
/* 服务宗旨 tenet */
.tenetWrap {
  width: 1920px;
  background: url("../../public/imgs/index/tenet.png") no-repeat center top;
  height: 420px;
  overflow: hidden;
}

.tenetTitle {
  width: 1700px;
  height: 55px;
  background: url("../../public/imgs/index/service.png") no-repeat left center;
  margin: 50px auto 0;
  border-bottom: 1px solid #ddd;
}
.tenetText {
  width: 65%;
  height: 355px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 5px;
  margin: 0 auto;
  padding: 66px 150px;
}
.tenetText > p:nth-of-type(1) {
  font-weight: 900;
  font-size: 36px;
  text-align: center;
  color: #fff;
}
.tenetText > p:nth-of-type(2) {
  margin: 10px auto 50px;
  width: 60px;
  height: 1.5px;
  background: #fff;
}
.tenetText > div {
  line-height: 30px;
}
</style>
