<template>
  <div class="wrapFooter">
    <div class="form">
      <el-input v-model="name" placeholder="姓名（必填）"></el-input>
      <el-input v-model="phone" placeholder="电话（必填）"></el-input>
      <el-input v-model="address" placeholder="地址"></el-input>
      <el-button type="primary">提交</el-button>
    </div>
    <div class="nav">
      <ul>
        <li @click="jump('1')">首页</li>
        <li @click="jump('2')">服务案例</li>
        <li @click="jump('3')">新闻资讯</li>
        <li @click="jump('4')">业务伙伴</li>
        <li @click="jump('5')">关于我们</li>
      </ul>
      <div class="phoneAll">
        <div>
          <p>联系我们</p>
          <p>西安创星装饰工程有限公司</p>
          <p>地址：西安市雁塔区唐延路旺座国际E座2702室</p>
        </div>
        <div>
          <p>服务热线</p>
          <p style="margin-top: 45px; font-size: 26px">
            <img
              src="../../public/imgs/indexNew/phoneImg.png"
              alt=""
              style="vertical-align: middle; margin-right: 20px"
            />
            029-87552665
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      name: "",
      phone: "",
      address: "",
    };
  },
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
.wrapFooter {
  background: url("../../public/imgs/indexNew/footer.png") no-repeat center
    center;
  width: 1920px;
  height: 380px;
  overflow: hidden;
}
ul,
li {
  list-style: none;
}
.form {
  width: 500px;
  margin-left: 390px;
  margin-top: 54px;
  float: left;
}
.el-input,
.el-button {
  width: 500px;
  height: 44px;
  display: block;
  margin-bottom: 30px;
}
.el-input >>> .el-input__inner {
  background: #474747;
  border: 0;
  color: #999;
}

.nav {
  float: left;
  width: 900px;
  margin-left: 50px;
  margin-top: 54px;
}
.nav ul {
  margin: 0;
}
.nav li {
  float: left;
  margin-right: 60px;
  color: #999;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.phoneAll {
  margin-top: 88px;
  margin-left: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #999999;
}
.phoneAll > div {
  float: left;
  margin-right: 100px;
}
</style>
